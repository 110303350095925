.ProfileContainer {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 10px;
  display: block;
  padding: 20px 20px 20px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin: 10px 0px;
}

.HomeContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  // padding: 20px 20px 20px 20px;
  // background-color: #f5f5f5;
  border-radius: 10px;
  // margin: 10px 0px;
}

.channelContainer {
  padding-top: 0px;
}

.ProfilePhotoContainer {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 15px;
  align-items: center;
  // margin-left: 5px;
  .org-icon-div {
    span {
      font-weight: 300;
    }
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-bottom: 3px;
    &:hover img {
      display: inline-block;
    }
    img {
      cursor: pointer;
      display: none;
      height: 4px;
      padding-right: 11px !important;
    }
  }

  .useName {
    h1 {
      text-transform: capitalize;
      font-size: 22px;
    }
  }

  .useName a {
    background-color: #fff;
    color: #fe0000;
    // border-width: 1px;
    padding: 3px 16px;
    line-height: 1;
    font-size: 12px;

    // background: #fe0000;
    // color: #ffffff;
    border: 1px solid #fe0000;
    border-radius: 25px;
    // font-size: 11px;
    // padding: 1px 14px 2px 14px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    margin-top: 2px;
    &:hover {
      color: #fe0000;
      background: #ffffff;
      border: 2px solid #fe0000;
      text-decoration: none;
    }
  }
}

.ProfilePhotoContainer h1 {
  color: #000000;
  font-size: 24px;
  margin: 0;
  font-weight: 500;
}

.ProfilePhotoContainer p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 3px;
  display: block;
}

.ExperienceContainer {
  background: #ffffff;
  // border: 1px solid #cdc7c7;
  border-radius: 10px;
  margin-top: 12px;
  padding: 1px 0;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
}

.ExperienceContainer .header {
  border-bottom: 1px solid #cdc7c7;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
    height: 16px;
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
    }
  }
}

.ExperienceContainer .header p {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  text-transform: lowercase;
}

.ExperienceContainer .notFound {
  padding-left: 10px;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 300;
  text-transform: lowercase;
}

.ExperienceContainer .Content {
  padding: 10px;
  padding-bottom: 0px;
  position: relative;

  .pbm-post-menu {
    top: 15px;
    right: 10px;
    width: 15px;
  }
}

.ExperienceContainer .Content .Title {
  display: flex;
  justify-content: space-between;
}

.ExperienceContainer .Content .subTitle h6 {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0px;
  padding-right: 25px;
}

.ExperienceContainer .Content .subTitle span {
  font-weight: 300;
}

.ExperienceContainer .Content .Title p {
  color: #5b5b55;
  font-size: 13px;
  margin-bottom: 7px;
}

.ExperienceContainer .Content .years {
  text-align: right;
}

.ExperienceContainer .Content .years img {
  opacity: 0.3;
}

.ExperienceContainer .Content .years span {
  font-size: 14px;
  color: #5b5b5b;
}

.ExperienceContainer .Content .text p {
  color: #404040;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.seeMore {
  color: #fe0000;
  font-weight: 600;
}

.ExperienceContainer hr {
  border-top: 1px solid #cdc7c7;
  width: 95%;
  margin-bottom: 7px;
}

.FollowerSection {
  background: #ffffff;
  // border: 1px solid #cdc7c7; 
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
}

.FollowerSection .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.FollowerSection p {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}

.FollowerSection h1 {
  font-size: 22px;
  font-weight: 300;
  color: #292929;
}

.FollowersMain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.FollowingMain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.FollowingWrapper {
  padding-top: 30px;
}

.PostBoxContainer {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cdc7c7;
  margin-bottom: 10px;
}

.postText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cdc7c7;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.postComment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.statusBox {
  width: 100%;
}

.statusBox input {
  background: #f6f6f6;
  outline: none;
  border: none;
  margin-left: 10px;
  border-radius: 50px;
  width: 98%;
  padding: 10px;
  padding: 7px 10px;
}

.statusBox input::placeholder {
  color: #3a3a3a;
  font-weight: 300;
}

.ActivityBoxContainer {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cdc7c7;
  margin-bottom: 10px;
}

.ActivityBoxContainer .header {
  display: flex;
  justify-content: space-between;
}

.userDetailContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ActivityBoxContainer .userDetail span {
  color: #707070;
  font-size: 12px;
  font-weight: 300;
}

.Attachments {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Attachments span {
  color: #707070;
  font-weight: 400;
  font-size: 15px;
}

.Status p {
  color: #404040;
  font-weight: 300;
}

.statusContainer {
  margin-top: 10px;
}

.LikeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-bottom: 1px solid #cdc7c7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.likeImg {
  padding: 7px;
  background-color: #ff0000;
  border-radius: 50%;
  margin: auto 5px auto 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.CommentCount span {
  color: #707070;
  font-weight: 400;
}

.Message p {
  margin: 0;
}

.userImage img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.userImg img {
  object-fit: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.divWithScrollbar {
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  // width: 260px;
  // height: 730px;
  // overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 10px;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: #fff;
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: #f5f5f5;
  //   border-radius: 10px;
  //   height: 25px;
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #f5f5f5;
  // }
}

.followers_main {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cdc7c7;
  margin-bottom: 10px;
  .followers_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 12px;
      margin-left: 5px;
    }
    h3 {
      font-size: 18px;
      color: #5f5f5f;
    }
    a {
      display: flex;
      align-items: center;
      color: #9d9d9d;
      svg {
        color: #fe0000;
      }
    }
  }
  .followers_details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #5f5f5f;
      &:hover {
        color: #5f5f5f;
        text-decoration: none;
      }
      img {
        width: 100%;
        border-radius: 16px;
      }
    }
  }
  .youre_following {
    margin-bottom: 20px;
  }
  .your_followers {
  }
}
.follow-button a {
  display: inline-block;
  background-color: #e80405;
  border-radius: 20px;
  padding: 5px 21px;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
}

.profile_stars_and_followers {
  margin-top: 15px;
  // margin-left: 5px;

  .row {
    line-height: 1;
    align-items: center;
  }

  .CustomDropDown {
    margin-right: 8px;
    .dropdown {
      button {
        padding: 0;
        display: flex;
        align-items: center;

        &:after {
          top: 0px;
        }
        span {
          font-size: 18px;
        }
      }
    }
  }

  .dropdown {
    img {
      height: 17px;
      width: inherit;
      border-radius: 0;
    }
  }

  .label_followers a {
    text-decoration: none;
  }

  .label_followers {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-right: 8px;
    .img_follow,
    .img_elves,
    .img_aibadge,
    .img_introducedby {
      height: 17px;
      margin-right: 3px;
    }
    img {
      margin-right: 3px;
    }

    &:first-child {
      // margin-bottom: 0px;
    }
    span {
      color: #000000;
      font-size: 18px;
      font-weight: 300;
      display: flex;
      align-items: center;
      line-height: 1;
      a {
        margin-left: 12px;
        color: #ffffff;
        background: #88bbfd;
        border-radius: 25px;
        font-size: 12px;
        padding: 1px 14px 2px 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        height: 20px;
        &:hover {
          text-decoration: none;
        }
      }
    }

    a.label {
      color: #ffffff;
      background: #88bbfd;
      border-radius: 25px;
      font-size: 12px;
      padding: 1px 14px 2px 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      height: 20px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.hint_followers {
  margin-top: 10px;
  p {
    background: #ffffff;
    border-radius: 25px;
    padding: 5px 15px;
    font-size: 14px;
    color: #b0b0b0;
    font-weight: 300;
  }
}

.loaderImg {
  text-align: center;
}

.channel-bar {
  display: grid;
  gap: 12px;
  grid-template-rows: min-content min-content min-content;
  .tabs-selector {
    background-color: white;
    border-radius: 10px;
    // border: 1px solid #c4c4c4;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2%);


    button.active {
      background-color: black;
      color: white;
    }

    button {
      cursor: pointer;
      background-color: white;
      border: 0;
      font-size: 15px;
      padding: 18px 0;
      display: grid;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        margin: 0 auto 2px;
      }
      &:first-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // border-right: 1px solid #c4c4c4!important;
      }
      &:last-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // border-right: 1px solid #c4c4c4!important;
      }
      span {
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
      }
    }
  }
}

.meeting-live-dive.up-next{
  .SessionContent{
    box-shadow: none;
  }
}

.RightSideBarContainer.profile-bar {
  .meeting-live-dive {
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    margin-bottom: 12px;

    .SessionContent{
      box-shadow: unset;
    }

  }
}

.showMorebtn{
  margin-top: 10px;
  span{
    cursor: pointer;
  }
}

.RightSideBarContainer {
  margin-top: 10px;
  // background-color: #ffffff;
  // border-radius: 10px;

  .meeting-name{
    line-height: 1;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .no-padding.meeting-live-dive{
    padding: 0;
  }

 

  .meeting-live-dive h1 {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 22px;
    text-transform: capitalize;
    text-align: center;
    line-height: 1.3 !important;
    word-break: break-all;
    padding-top: 11px;
  }



  .meeting-live-dive {
    position: relative;
    // margin-bottom: 12px;
    padding: 20px 10px;
    background-color: #ffffff;
    display: grid;
    gap: 10px;
    border-radius: 10px;
    justify-content: center;
    font-family: "Roboto slab";
    box-shadow: 0 1px 2px rgba(0,0,0,.2);


    span {
      position: absolute;
      right: 12px;
      top: 6px;
      font-size: 12px;
      color: #fe0000;
      font-family: "Roboto slab";
      font-weight: 500;
    }

    h1 {
      margin-bottom: 0;
      font-weight: 300;
      font-size: 22px;
      text-transform: capitalize;
      text-align: center;
      line-height: 1;
      word-break: break-word;
      font-family: 'Lato', sans-serif;
    }

    .image-container {
      max-height: 95px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .image-box {
        margin-right: 5px;
        // margin-bottom: 5px;
        &:nth-child(5) {
          margin-right: 0;
          // margin-top: 5px;
        }
        &:nth-child(4n) {
          // margin-right: 0;
        }
        position: relative;
        padding-bottom: 45px;
        width: 45px;
        border-radius: 50%;
        overflow: hidden;
        img {
          position: absolute;
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
      .more-members-count {
        span {
          color: #7c7c7c;
          font-size: 20px;
          margin-right: 9px;
        }
      }
    }

    .join-btn {
      display: grid;
      justify-content: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: rgb(124, 124, 124);
        cursor: pointer;
        line-height: 1;

        img {
          cursor: pointer;
          height: 15px;
          width: 15px;
          margin-left: 5px;
        }
      }
    }
  }

  .meeting-live-dive.up-next{
    margin-bottom: 10px;
    &:last-of-type{
      margin-bottom: 0;
    }
    // padding: 0;
    // background-color: transparent;

    h1{
      font-size: 18px;
    }

    .agenda-view{
      padding: 0;
      box-shadow: none;
      gap: 0px;

      .channel-name{
        margin-top: 5px;
        color: #ff0000;
      }

      .host-image{
        width: 52px;
        padding-bottom: 48px;
      }
      .inner{
        margin-top: 15px;
      }
    }

    .date p{
      font-size: 14px;
      font-weight: 300;
    }
  }

  .divWithScrollbar {
    background-color: #ffffff;
    padding-top: 10px;
    border-radius: 10px;
    // border: 1px solid #c4c4c4;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    .chat-container {
      height: 730px;
      overflow-y: scroll;
      padding: 0 10px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
    }

    .Search {
      background-color: #f1f2f6;
      display: flex;
      align-items: center;
      padding: 5px 8px;
      border-radius: 6px;
      margin: 0 10px 10px;
      border: 1px solid #e5e6ea;
      font-size: 14px;

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.icon-Angel-investor {
  height: 17px;
  width: inherit;
  border-radius: 0;
}
