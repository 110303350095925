.Navbar {
  background: #ffffff;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 90px;
  margin-right: 90px;
  /* padding-top: 10px; */
  transition: all 0.3s ease-in-out;
}

.mobile-menu {
  /* display: none; */
  width: 300px;
}

.CustomDropDown button {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}

.CustomDropDown span {
  color: #000000 !important;
  font-size: 18px;
  font-weight: 300;
  margin-left: 4px;
}

.CustomDropDown img {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  object-fit: cover;
}

.CustomDropDown.dropdown-toggle {
  background: yellow;
}

.CustomDropDown button::after {
  position: relative;
  top: 4px;
  border-radius: 4px;
  border-top: 0.6em solid #ff0000;
  border-right: 0.35em solid transparent;
  border-bottom: 0em solid transparent;
  border-left: 0.35em solid transparent;
}

.CustomDropDown button:hover {
  background: transparent;
}

.CustomDropDown button {
  padding-right: 0;
}

.goLive button {
  background: #ff0000;
  color: #fff;
  border: 1px solid #ff0000;
  border-radius: 50px;
  font-size: 14px;
  padding: 5px 15px;
  font-weight: 600;
  outline: none;
}

.goLive {
  margin-right: 10px;
}

.goLive a {
  color: #fff;
}

.createEvent button {
  background: #cecece;
  color: #fff;
  border: 1px solid #cecece;
  border-radius: 50px;
  font-size: 14px;
  padding: 5px 15px;
  font-weight: 600;
  outline: none;
}

.headerRightContent {
  display: flex;
  align-items: center;
}

.createEvent,
.Message,
.Notification {
  margin-right: 10px;
}

.ChannelName,
.logoContainer {
  display: flex;
  align-items: center;
}

.ChannelName {
  margin-left: 10px;
}

.ChannelName button {
  background: transparent !important;
  color: #000000 !important;
  border: none;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 300;
  padding-left: 4px;
}

.ChannelName button::after {
  position: relative;
  top: 6px;
  border-radius: 4px;
  border-top: 0.6em solid #ff0000;
  border-right: 0.35em solid transparent;
  border-bottom: 0em solid transparent;
  border-left: 0.35em solid transparent;
}

.ChannelName span {
  font-size: 18px;
  font-weight: 300;
}

.ChannelName dropdown {
  font-size: 20px;
  font-weight: 300;
  margin-left: -5px;
}

.CustomDropDown a {
  color: #000000;
}

.CustomDropDownWithLongText {
  padding-right: 20px !important;
}

.navbar_links {
  margin-left: 10px;
}
.navbar_links a {
  margin-right: 20px;
  color: #707070;
  color: #000000 !important;
  font-size: 18px;
  font-weight: 300;
}
.navbar_links a.active {
  font-weight: 500;
}
.navbar_links a:hover {
  text-decoration: none;
}


.search-bar {
  /* background-color: #f1f2f6; */
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  position: relative;
  padding: 4px 30px 4px 10px;
}

.search-bar input {
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  /* font-family: 'Roboto slab'; */
}
.search-bar input:focus {
  outline: none;
}

.search-bar img {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
}

@media only screen and (max-width: 1100px) {
  .Navbar {
    margin: 0;
    padding: 0 90px;
  }

  .navbarBrand img {
    height: 25px;
  }

  .ChannelName {
    margin-left: 5px;
  }

  .ChannelName button {
    font-size: 16px;
  }
  .ChannelName button:after {
    top: 4px;
  }

  .navbar_links {
    margin-left: 5px;
  }

  .CustomDropDown span {
    font-size: 16px;
  }

  .navbar_links a {
    margin-right: 10px;
    font-size: 16px;
  }

  .headerRightContent {
    align-items: center;
  }

  .create-live-event-btn {
    font-size: 16px;
  }

  .CustomDropDown button {
    padding-left: 0;
  }

  .CustomDropDown img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .Navbar {
    margin: 0;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .ChannelName,
  .navbar_links,
  .create-live-event-btn,
  .navbarBrand {
    display: none;
  }
  .CustomDropDownWithLongText {
    padding-right: 0 !important;
  }

  .headerRightContent {
    margin-left: 10px;
  }

  .CustomDropDown img {
    height: 35px;
    width: 35px;
  }

  .logoContainer {
    flex: 1;
  }

  .logoContainer a img{
    display: none;
  }

  .mobile-menu {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: auto 1fr;
  }

  .mobile-icon {
    display: grid;
    align-items: center;
  }

  #nav-icon4 {
    width: 30px;
    height: 23px;
    position: relative;
    /* margin: 50px auto; */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #707070;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 0px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 0px;
  }

  .mobile-icon img {
    cursor: pointer;
    height: 23px;
    width: 23px;
  }

  
}

@media only screen and (max-width: 400px) {
  .Navbar {
    padding: 0 3px 0 10px;
  }
  .CustomDropDown span,
  .CustomDropDown button::after {
    display: none;
  }
}
