.SpeakerWrapper {
  height: 100vh;
  overflow-y: scroll;
  /* background: #f0f0f0; */
  margin-top: 30px;
}

.SpeakerWrapper::-webkit-scrollbar {
  display: none;
}

.SpeakerContainer {
  /* padding: 15px; */
}

.speaker {
  position: relative;
  /* gap: 5px; */
  /* justify-content: center; */
  /* align-items: center; */
  /* display: grid; */
}

.speaker .image-div {
  position: relative;
  padding-bottom: 100%;
  border-radius: 5px;
  overflow: hidden;
  /* width: 100px; */
}

.image-div a {
  color: black;
}

.speaker .pbm-post-menu {
  top: 8px;
  right: 7px;
}

.speaker p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding-top: 5px;
  line-height: 1.3;
  margin: 0;
  text-align: left;
}

.speaker p a {
  color: #000000;
}

.speaker span {
  font-size: 14px;
  color: #212529;
  font-weight: 300;
  display: block;
  line-height: 1.3;
}

.title h2 {
  color: #404040;
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 15px;
}

.ourSpeakers {
  display: grid;
  grid-template-columns: repeat(4, 23.33%);
  margin-top: 20px;
  gap: 10px;
  grid-row-gap: 30px;
  /* align-items: center; */
}

.ourSpeakers.remove-grid {
  grid-template-columns: 1fr;
}

.ourSpeakers.remove-grid h5 {
  font-weight: 300;
}

.add-btn-gnrl-channel button {
  background-color: transparent;
  /* color: #fff; */
  appearance: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  /* padding: 10px 30px; */
  margin-top: 20px;
  text-decoration: underline;
  /* border-radius: 8px; */
  line-height: 1;
  /* margin-right: 10px; */
}

.speaker img {
  filter: grayscale(100%);
  transition: all 0.35s ease-in-out;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  cursor: pointer;
}

.speaker img.partner-img,
.speaker .image-div img {
  /* object-fit: contain; */
}

.speaker img:hover {
  filter: grayscale(0%);
}

@media only screen and (max-width: 900px) {
  .ourSpeakers {
    grid-template-columns: repeat(3, 33.33%);
  }
}

@media only screen and (max-width: 600px) {
  .ourSpeakers {
    grid-template-columns: 1fr;
  }
}
