#tribe_story {
  margin-top: 65px;
  background-color: white;
  padding-bottom: 10px;
  .react-multi-carousel-list {
    position: inherit;
  }

  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    margin-top: -30px;
    z-index: 999999999;
  }

  .inner {
    width: calc(100% - 180px);
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    // overflow: auto;
    white-space: nowrap;
    text-align: center;
    //padding: 15px;
  }

  .img-arrow {
    margin-top: 45px;
    cursor: pointer;
  }

  .channel-layout {
    display: inline-block;
    inline-size: 188px;
    overflow: hidden;
    cursor: pointer;

    .cover-image {
      position: relative;
      width: 160px;
      height: 90px;
      border-radius: 6px;
      overflow: hidden;
      margin: auto auto 10px;
      border: 1px solid #e4e4e4;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    .channel-name {
      //width: 11vw;
      margin: auto;
      /* text-align: justify; */
      word-break: break-word;
      white-space: break-spaces;
      overflow-wrap: break-word;
    }
  }
}

@media only screen and (max-width: 991px) {
  #tribe_story {
    margin-top: 75px;
    padding-bottom: 0;
    background-color: #f4f4f4;
    .react-multiple-carousel__arrow--right {
      margin-right: -14px;
      margin-top: -25px;
      z-index: 999999999;
    }
    .react-multiple-carousel__arrow--left {
      margin-left: -14px;
      margin-top: -25px;
      z-index: 999999999;
    }
    .inner {
      width: calc(100% - 20px);
      background-color: white;
    }

    .channel-layout {
      width: 80%;
      .cover-image {
        width: 120px;
        height: 120px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #tribe_story {
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left {
      min-width: 30px;
      min-height: 0px;
    }
    .channel-layout {
      width: 30%;
      .cover-image {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  #tribe_story {
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left {
      min-width: 30px;
      min-height: 0px;
    }
    .channel-layout {
      width: 75%;
      .cover-image {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.active-channel {
  background-color: #f3efef;
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 4px;
}
