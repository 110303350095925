.AgendaWrapper {
  /* height: 100vh; */
  /* overflow-y: scroll; */
  /* background: #f0f0f0; */
  margin-top: 30px;
}

.AgendaWrapper::-webkit-scrollbar {
  display: none;
}

.title h2 {
  color: #404040;
  font-weight: 300;
  font-size: 22px;
}

.title span {
  font-size: 16px;
  text-decoration: underline;
  padding-left: 10px;
  cursor: pointer;
}

.AgendaContentWrapper {
  /* padding: 20px; */
}

.AgendaContainer {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);
  grid-gap: 10px;
  margin-top: 20px;
}

.AgendaContainer h5 {
  text-transform: lowercase;
  font-weight: 300;
}

.UpcomingSessions {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  display: grid;
  gap: 20px;
  height: max-content;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.2);
}

.UpcomingSessions .Session {
  cursor: pointer;
  padding: 5px;
}

.UpcomingSessions .Session:hover {
  /* box-shadow: 0px 1px 3px -1px;
  border-radius: 5px; */
}

.SessionContent {
  position: relative;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.SessionContent .channel-name {
  font-size: 16px;
  font-weight: 300;
}

.SessionContent > a {
  display: unset !important;
  position: unset !important;
  text-decoration: none;
  color: #212529;
}
.SessionContent a img {
  height: 100% !important;
}

.SessionContent a {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  cursor: pointer;
}

.SessionContent a img {
  height: 16px;
}

.SessionContent .float-right {
  position: absolute !important;
  height: 20px;
  top: 0;
  z-index: 999;
}

.SessionContent img {
  border-radius: 10px;
}

.Session {
  margin-bottom: 0px;
}
.Session a {
  text-decoration: none;
}

.Session p {
  color: #080808;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 1;
}

.Session.active p {
  text-decoration: underline;
}

.SessionContent .session-image {
  position: relative;
  padding-bottom: 56.25%;
}

.session-image img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top left;
}

.session-image .cover-image {
  position: absolute;
  height: 100%;
  width: 100%;
}

.Session span {
  font-size: 14px;
  color: #212529;
  font-weight: 300;
  /* margin-left: 5px; */
}

.VideosContainer {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
}

.VideoImage img {
  border-radius: 10px;
}

.agenda-view {
  /* background-color: white; */
  /* border-radius: 10px; */
  padding: 40px 25px;
  display: grid;
  justify-content: center;
  gap: 25px;
}

.agenda-view h3 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 0;
  text-align: center;
}

.agenda-view h4 {
  font-size: 20px;
  text-align: center;
  font-weight: 300;
}

.agenda-view .event-date {
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.3;
}

.agenda-view .inner {
  display: grid;
  gap: 10px;
  text-align: center;
}

.host-image {
  position: relative;
  overflow: hidden;
  margin: auto;
  width: 100px;
  padding-bottom: 90%;
  border-radius: 50%;
  border: 2px solid white;
}

.detail p,
.date p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  white-space: break-spaces;
}

.detail {
  /* margin-top: 20px; */
}

.date p {
  font-weight: 400;
  /* margin-top: 20px; */
}

.speakers {
  display: flex;
  /* max-width: 185px; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  justify-content: center;
  /* gap: 10px; */
}

.img {
  cursor: pointer;
  margin-left: -10px;
  position: relative;
}

.img .overflow-modal {
  position: absolute;
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 6px;
  /* border: 1px solid #c4c4c4; */
  width: max-content;
  gap: 5px;
  left: -50%;
  color: white;
}

.overflow-modal span {
  text-overflow: ellipsis;
  word-break: break-word;
}

.overflow-modal h4 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.img:hover .overflow-modal {
  display: grid;
}

.img span {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
}

.host-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.agenda-view .event-date span {
  font-weight: 400;
}

.agenda-view button {
  background-color: #32bcf6;
  color: white;
  line-height: 1;
  padding: 8px;
  border-radius: 6px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .AgendaContainer {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 567px) {
  .AgendaContentWrapper {
    padding: 10px;
  }

  .AgendaContainer {
    grid-template-columns: 1fr;
  }

  .agenda-view h3 {
    font-size: 18px;
  }
  .agenda-view h4 {
    font-size: 16px;
  }
}
