.channelHeaderContainer {
  background: #00cae7;
  /* display: grid; */
  border-radius: 12px;
  align-items: center;
  position: relative;
  /* max-height: 190px; */
  overflow: hidden;

  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-top: 24.72%;
}

.channelHeaderContainer.no-bg-color{
  background-color: transparent;
}

.channelHeaderContainer img {
  /* height: 100%; */
  /* object-fit: cover; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  right: 0;
  bottom: 0;
  display: block;
}

.channelLogo {
  text-align: center;
}

.channelName h1 {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
}

.channelJoin {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: baseline;
  grid-gap: 5px;
}

.channelWithoutJoin {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: baseline;
  grid-gap: 5px;
}

.channelJoin span {
  color: #fff;
  font-size: 14px;
}

.channelJoin button {
  box-sizing: border-box;
  flex-basis: 49%;
  display: inline-block;
  padding: 10px 0;
  background-color: #ffffff;
  outline: none;
  border: 1px solid #cdc7c7;
  border-radius: 10px;
  cursor: pointer;
  color: #4a4a4a;
  font-size: 15px;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.channelJoin button:hover {
  background: #676767;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.uploadImage {
  position: absolute;
  top: 10px;
  right: 10px;
}

.uploadImage > input {
  display: none;
}

.uploadImage img {
  cursor: pointer;
}

.image-preview img {
  height: 190px;
  padding: 10px;
}

.combine-message .chat p {
  margin-bottom: 0px !important;
}

.channelBtnName {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  text-decoration: underline;
  line-height: 1;
}
