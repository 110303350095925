.DashboardMainContent.single-grid {
  grid-template-columns: 90px 1fr 90px;
  height: 100vh;
  grid-template-rows: 1fr;

  .new-login-screen {
    margin-top: 20px;
    justify-content: unset;
    .inner {
      width: 100%;
    }
  }
  .step3 {
    padding: 20px;
    text-align: center;
    gap: 20px;
    h3 {
      color: #707070;
    }
  }
}

.DashboardMainContent.channel-grid {
  grid-template-columns: 90px 1.5fr 90px;
  background: white;
  gap: 0;
}

.main-cover {
  background-color: white;
  margin: 65px 0 0;
  width: 100%;

  .inside-cover {
    position: relative;
    height: 100%;
    position: relative;
    padding-bottom: 25%;
    width: calc(100% - 180px);
    margin: auto;
    background-color: #f4f4f4;
    // background-color: #f6f6f6;

    img {
      position: absolute;
      inset: 0;
      height: calc(100%);
      width: calc(100%);
      object-fit: cover;
      // margin: 10px auto;
      // border-radius: 10px;
    }
  }
}

.home-main-cover {
  margin-top: 0px;
}

.ProfileTopContainer {
  margin-top: 63px;
}

.DashboardMainContent.channel {
  margin-top: 0;
}

.DashboardMainContent.without-chat-grid {
  grid-template-columns: 90px 1fr 90px;
}

.DashboardMainContent {
  display: grid;
  grid-template-columns: 90px 1.5fr 0.5fr 90px;
  // margin-top: 65px;
  gap: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .loader-view {
    margin-top: 10px;
    border-radius: 10px;
    height: 100vh;
    width: 100%;
    background-color: #dedede;
  }

  .profile-wrapper {
    margin-top: 10px;
  }

  .profile-wrapper.double-grid {
    display: grid;
    grid-template-columns: 0.508fr 1fr;
    gap: 10px;
  }

  .ProfileContainer {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .ProfileContainer.post-detail {
    grid-template-columns: 1fr;
  }

  .channel-inner-main {
    // margin-top: 10px;
    .channels_header {
      padding: 0;
    }

    .nav {
      display: none;
    }

    .profile-wrapper {
      margin-top: 12px;
    }

    .join-channel-btn-div {
      margin-top: 10px;
      // text-align: center;
      font-family: "Roboto Slab";
      border-radius: 10px;
      padding: 10px;
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2%);

      .channel-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          .dropdown {
            background-color: transparent;

            button {
              background-color: transparent;
              color: #000;
              padding: 0;
              line-height: 1.5;
              font-size: 20px;
              margin-left: 10px;
              border: 0;
              font-weight: 300 !important;

              &:after {
                position: relative;
                top: 4px;
                border-radius: 4px;
                border-top: 0.5em solid #ff0000;
                border-right: 0.35em solid transparent;
                border-bottom: 0em solid transparent;
                border-left: 0.35em solid transparent;
              }
              &:focus,
              &:hover,
              &:active {
                outline: none;
                border: none;
                box-shadow: none;
              }
            }
          }

          button.add-topic-btn {
            margin-left: 10px;
            cursor: pointer;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            line-height: 1;
            padding: 3px 5px;
            display: grid;
            align-items: center;
            justify-content: center;
            position: relative;
            span {
              display: grid;
              align-items: center;
              justify-content: center;
              img {
                height: 16px;
              }
            }

            span[data-tooltip]:before {
              content: "";
              position: absolute;
              border-top: 5px solid #0090ff;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              top: -10px;
              display: none;
              left: 50%;
              transform: translateX(-50%);
            }

            span[data-tooltip]:after {
              content: attr(data-tooltip);
              position: absolute;
              color: white;
              top: -28px;
              left: -1em;
              background: #0090ff;
              padding: 0.25em 0.75em;
              border-radius: 6px;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 300;
              display: none;
            }

            span[data-tooltip]:hover:before,
            span[data-tooltip]:hover:after {
              display: inline;
            }
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            font-size: 16px;
            color: #707070;
            font-weight: 300;
          }

          button {
            background-color: #32bcf6;
            border: 0;
            border-radius: 6px;
            color: white;
            line-height: 1;
            padding: 7px 14px;
            font-size: 16px;
            cursor: pointer;
            margin-left: 10px;

            &:focus,
            &:hover,
            &:active {
              box-shadow: none;
              outline: none;
              border: 0;
            }
          }
        }
      }

      p {
        margin-bottom: 0;
        font-weight: 300;
        font-size: 20px;
        line-height: 1;

        span {
          font-weight: 500;
        }

        span.ozi {
          color: #ff8888;
          font-weight: 300;
        }
        a {
          background-color: #ff8888;
          text-decoration: none;
          line-height: 1;
          display: inline-block;
          border-radius: 20px;
          font-size: 18px;
          color: #fff;
          padding: 4px 25px;
          box-sizing: content-box;
          font-weight: 400;
          margin: 0 6px;
        }
      }
    }
  }

  .channels_form {
    width: 80%;
    margin: 10px auto 0;

    h3 {
      font-size: 22px;
    }

    form {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;

      div {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 5px;
        }

        input {
          border-radius: 6px;
          font-size: 12px;
          padding: 10px;
          color: #4a4a4a;
          font-weight: 300;
        }

        textarea {
          border: 0;
          resize: none;
          border-radius: 6px;
          font-size: 12px;
          padding: 10px;
          color: #4a4a4a;
          font-weight: 300;
          &:focus {
            outline: none;
          }
        }
      }

      input {
        border-radius: 6px;
        font-size: 12px;
        padding: 10px;
        color: #4a4a4a;
        font-weight: 300;
      }

      textarea {
        border: 0;
        resize: none;
        border-radius: 6px;
        font-size: 12px;
        padding: 10px;
        color: #4a4a4a;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }

      .image-div {
        position: relative;
        background-color: white;
        padding: 5px;
        border-radius: 6px;
        label {
          cursor: pointer;
          margin-bottom: 0;
        }
        input[type="file"] {
          visibility: hidden;
          position: absolute;
          inset: 0;
        }
        .image-preview {
          position: relative;
          padding-bottom: 120px;
          img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .image-div a {
        color: black;
      }
      .btn-div {
        align-items: flex-end;
      }

      button {
        width: 120px;
        border: 0;
        background-color: #32bcf6;
        color: white;
        cursor: pointer;
        border-radius: 6px;
        padding: 8px;
        line-height: 1;
        margin-top: 5px;
      }
    }
  }

  .channels_container {
    margin-top: 10px;
    // padding: 15px;
    position: relative;
    input {
      width: 100%;
      border-radius: 10px;
      padding: 7px 25px;
      border: none;
      &::placeholder {
        color: #a2a2a2;
      }
      &:focus {
        outline: none;
      }
      margin-bottom: 10px;
    }
    .channels_inner {
      display: grid;
      // grid-template: 1fr;
      grid-gap: 30px;
      .channel_card {
        position: relative;
        span {
          position: absolute;
          right: 10px;
          top: 10px;
          background: #ffa600;
          text-align: center;
          width: 70px;
          color: #ffffff;
          border-radius: 15px;
          font-size: 12px;
        }
        background: #ffffff;
        border-radius: 10px;
        display: grid;
        align-items: center;
        justify-content: center;
        padding: 45px 10px;
        div {
          text-align: center;
          h1 {
            font-size: 28px;
            font-weight: 300;
            margin-bottom: 0px;
            font-family: "Roboto Slab";
            a {
              color: #333;
              font-size: 22px;
              font-family: "Roboto Slab";
            }
          }
          p {
            color: #a2a2a2;
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 300;
            font-family: "Roboto Slab";
          }
        }
        .channels_buttons {
          display: flex;
          grid-gap: 2px;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-content: center;
          a {
            text-align: center;
            padding: 5px 0;
            width: 85px;
            color: #ffffff;
            border-radius: 15px;
            font-size: 14px;
            line-height: 1;
            &:hover {
              text-decoration: none;
            }
            &.channel_about {
              background: #a2a2a2;
              position: relative;
              &:hover .chanel-desc {
                display: block;
              }

              .chanel-desc {
                display: none;
                position: absolute;
                top: 25px;
                background-color: rgba(0, 0, 0, 0.7);
                border-radius: 10px;
                padding: 10px;
                width: 200px;
                text-align: left;
                font-size: 13px;
                line-height: 1.4;
                font-weight: 400;
                letter-spacing: 0.4px;
                left: 0;
              }
            }
            &.channel_join {
              background: #76c51c;
            }
            &.channel_leave {
              background: red;
            }
          }

          .pbm-post-menu.sub-menu-channel {
            position: absolute !important;
            top: 10px;
            right: 10px;
          }
          .pbm-post-menu .post-menu-dropdown ul li.show:before {
            background: url("../svg/show.svg");
            height: 10px;
            width: 15px;
          }
          .pbm-post-menu .post-menu-dropdown ul li.visibility:before {
            background: url("../svg/visibility.svg");
            height: 10px;
            width: 15px;
          }
          .pbm-post-menu .post-menu-dropdown ul li.delete:before {
            background: url("../svg/bin.svg");
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

.channel-list {
  display: grid;
  grid-template-columns: 205px 1fr;
  gap: 15px;

  .channel-image {
    cursor: pointer;
    height: 90px;
    width: 205px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #e4e4e4;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .channel-description {
    display: grid;
    gap: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;

    h4 {
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      a {
        text-decoration: none;
        color: unset;
      }
    }

    p {
      margin-bottom: 0;
      line-height: 1.3;
      font-size: 14px;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul {
      margin-bottom: 0;
      li {
        font-size: 12px;
        display: inline-block;
        color: #707070;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.channelNewList {
  grid-template-columns: 20px 1fr;
  .descriptionh4 {
    margin-top: 0px !important;
  }
  .channel-description {
    padding-bottom: 5px;
  }
}

.channel-card-new {
  width: 100%;
  position: relative;
  &:last-of-type {
    margin-bottom: 30px;
  }

  .channel-cover {
    position: relative;
    padding-bottom: 30%;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(
      90deg,
      #f4f4f4 0px,
      rgba(229, 229, 229, 0.8) 40px,
      #f4f4f4 80px
    );
    // background-size: 600px;
    animation: shine-avatar 2s infinite ease-out;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      object-fit: cover;
      object-position: center;
    }
  }
  .pbm-post-menu {
    top: 8px;
    right: 5px;

    .post-menu-dropdown ul li.show:before {
      background: url("../svg/show.svg");
      height: 10px;
      width: 15px;
    }
    .post-menu-dropdown ul li.visibility:before {
      background: url("../svg/visibility.svg");
      height: 10px;
      width: 15px;
    }
  }

  .channel-bottom {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        align-items: center;
        font-weight: 300;
        font-size: 20px;
      }
    }

    .follow {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      align-items: center;

      .members {
        p {
          margin-bottom: 0;
          color: #707070;
          font-size: 18px;
          font-weight: 300;
        }
      }
      .join {
        a {
          border: 0;
          box-shadow: none;
          cursor: pointer;
          background-color: #32bcf6;
          color: white;
          padding: 6px 20px 8px;
          border-radius: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 1;
          text-decoration: none;
        }
      }
    }
  }
}

.DashboardMainContent.new-layout {
  grid-template-columns: 90px 0.5fr 1fr 0.5fr 90px;
}

.channels_header {
  // padding: 15px;
  position: relative;
  input {
    width: 100%;
    border-radius: 25px;
    padding: 7px 25px;
    border: none;
    &::placeholder {
      color: #a2a2a2;
    }
    &:focus {
      outline: none;
    }
    margin-bottom: 20px;
  }
  .channels_inner {
    display: grid;
    grid-template: 1fr;
    grid-gap: 10px;
    .channel_card {
      .description {
        position: relative;
      }
      span {
        position: absolute;
        right: -55px;
        top: -10px;
        background: #ffa600;
        text-align: center;
        width: 70px;
        color: #ffffff;
        border-radius: 15px;
        font-size: 12px;
      }
      background: #ffffff;
      border-radius: 10px;
      display: grid;
      align-items: center;
      justify-content: center;
      padding: 45px 10px;
      div {
        text-align: center;
        h1 {
          font-size: 28px;
          font-weight: 300;
          margin-bottom: 0px;
          font-family: "Roboto Slab";
        }
        p {
          color: #a2a2a2;
          font-size: 12px;
          margin-bottom: 0px;
          font-family: "Roboto Slab";
        }
      }
      .channels_buttons {
        display: flex;
        grid-gap: 2px;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        a {
          text-align: center;
          width: 70px;
          color: #ffffff;
          border-radius: 15px;
          font-size: 12px;
          &:hover {
            text-decoration: none;
          }
          &.channel_about {
            background: #a2a2a2;
          }
          &.channel_join {
            background: #76c51c;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .DashboardMainContent {
    .profile-wrapper {
      .HomeContainer {
        grid-template-columns: 1fr;
      }
    }
    .ProfileContainer {
      grid-template-columns: 1fr;
      gap: 12px;
    }
  }

  .tabs-content {
    grid-template-columns: 1fr !important;
    margin: 12px 0 0 0 !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    .main-title {
      border-radius: 0 !important;
      border-top-right-radius: 9px !important;
      border-top-left-radius: 9px !important;
    }

    ul {
      // gap: 10px !important;
      padding: 10px 0 !important;
    }
  }

  .arrow-down-gnrl {
    display: none;
  }

  .image-preview img {
    width: 100%;
  }

  .DashboardMainContent .channel-inner-main .join-channel-btn-div .channel-div {
    grid-template-columns: 1fr;
    gap: 10px;
    .left,
    .right {
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .DashboardMainContent {
    grid-template-columns: 90px 1fr 200px 90px;
  }
  .DashboardMainContent
    .channels_container
    .channels_inner
    .channel_card
    div
    h1
    a {
    font-size: 18px;
  }
  .DashboardMainContent .profile-wrapper.double-grid {
    grid-template-columns: 200px 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .DashboardMainContent .channels_container .channels_inner {
    grid-template-columns: 1fr;
  }

  .DashboardMainContent .profile-wrapper.double-grid {
    .RightSideBarContainer {
      display: none;
    }
  }

  .DashboardMainContent.new-layout {
    grid-template-columns: 0px 0.5fr 1fr 0.5fr 0px;
  }

  .channel-card-new .channel-bottom {
    grid-template-columns: 1fr;
    gap: 15px;
    .name,
    .follow {
      justify-content: center;
    }
  }

  .profile-wrapper.double-grid {
    grid-template-columns: 0px 1fr !important;
    gap: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .DashboardMainContent.channel-grid,
  .DashboardMainContent.single-grid {
    grid-template-columns: 1fr;
  }

  #channels_container .channel-grid {
    gap: 50px;
  }

  .main-cover .inside-cover {
    width: 100%;
    padding-bottom: 40%;
    img {
      object-position: left;
    }
  }

  .DashboardMainContent.new-layout {
    grid-template-columns: 0.5fr 1fr 0.5fr;
    nav {
      display: none;
    }
    .sidebar-component {
      .RightSideBarContainer {
        display: block;
      }
    }
  }
  .DashboardMainContent {
    padding: 0 10px;
    grid-template-columns: 1fr;
    nav {
      // position: absolute;
      // display: flex;
      // flex-direction: row;
      margin: 10px 0 0;
      border-radius: 6px;
      .nav-menu-items {
        // padding: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 0;
      }
    }

    .arrow-down-gnrl {
      display: none;
    }

    .channel-inner-main {
      .tabs-content {
        margin: 12px 0 0 0;
        grid-template-columns: 1fr;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

        .main-title {
          border-radius: 0;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
        }
      }
      .arrow-down-gnrl {
        top: calc(100% + 11px);
      }

      .tabs {
        padding: 10px 0;
      }

      .tabs-content ul {
        gap: 0;
        li {
          line-height: 1.1;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .channel-forms {
        width: 100%;
      }

      .image-preview img {
        width: 100%;
        object-fit: contain;
      }

      .nav {
        margin-top: 12px;
        display: block;
        border-radius: 10px;
        border: 1px solid #cdc7c7;
        ul {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          margin: 0;
          align-items: center;
          li {
            border-right: 1px solid #c4c4c4;
            padding: 10px 0;
            img {
              margin-bottom: 5px !important;
              height: 20px;
              width: 20px;
            }
            p {
              font-weight: 400;
            }
            &:last-of-type {
              border-right: 0;
            }
          }
        }
      }
    }
  }

  .DashboardMainContent .channels_container .channels_inner {
    // grid-template-columns: 1fr 1fr;
  }

  .RightSideBarContainer {
    display: none;
  }
}
.hideOthersMeeting {
  display: none;
}

@media only screen and (max-width: 600px) {
  .channel-list {
    grid-template-columns: 1fr;

    .channel-image {
      width: 100%;
      height: 140px;
    }
  }

  .DashboardMainContent.new-layout {
    grid-template-columns: 1fr;
    gap: 0;
    nav {
      margin: 0;
    }
    #sidebar_component {
      height: 0;
      padding: 0;
      #meeting_comp {
        margin-bottom: 0;
      }
      .RightSideBarContainer {
        display: none;
      }
    }
  }

  .DashboardMainContent {
    padding: 0 10px;
    grid-template-columns: 1fr;
  }

  .DashboardMainContent .channels_container .channels_inner {
    grid-template-columns: 1fr;
  }
  .channel-card-new .channel-cover {
    padding-bottom: 50%;
  }

  .channel-card-new .channel-bottom .name p {
    font-size: 16px;
  }

  .channel-card-new .channel-bottom .follow .members p {
    font-size: 14px;
  }

  .channel-card-new .channel-bottom .follow .join a {
    padding: 6px 16px;
    font-size: 14px;
  }
  .channel-div {
    .right {
      flex-direction: column;
      gap: 10px;
    }
  }
}

.linkedin-div h3 {
  color: green;
}

.create_channels_form {
  width: 350px;
  h3 {
    font-size: 17px;
  }
  form {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    text-align: initial;

    input {
      border-radius: 6px;
      font-size: 12px;
      padding: 10px;
      color: #4a4a4a;
      font-weight: 300;
      border: 1px solid #a3a3a3;
    }

    textarea {
      border: 0;
      resize: none;
      border-radius: 6px;
      font-size: 12px;
      padding: 10px;
      color: #4a4a4a;
      font-weight: 300;
      &:focus {
        outline: none;
      }
      border: 1px solid #a3a3a3;
    }

    .image-div {
      position: relative;
      background-color: white;
      padding: 5px;
      border-radius: 6px;
      label {
        cursor: pointer;
        margin-bottom: 0;
      }
      input[type="file"] {
        visibility: hidden;
        position: absolute;
        inset: 0;
      }
      .image-preview {
        position: relative;
        padding-bottom: 120px;
        img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .text-red {
      font-size: 12px;
    }

    button {
      width: 100%;
      border: 0;
      background-color: #333;
      color: white;
      cursor: pointer;
      border-radius: 6px;
      padding: 8px;
      line-height: 1;
      margin-top: 5px;
    }
  }
}
