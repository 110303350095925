.notifications-dropdown{
    margin-right: 15px;


    .dropdown{
        button.dropdown-toggle{
            background-color: #e4e6eb;
            padding: 6px;
            border-radius: 50%;
        }

        button{
            padding: 0;
            border: 0;
            display: flex;
      
            &:focus,&:active:focus{
              outline: none!important;
              box-shadow: none!important;
            }
      
            img{
              height: 20px;
            }
            
            &:after{
              display: none;
            }
          }
    }

    span.counting{
        position: absolute;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fe0000;
        border-radius: 50%;
        padding: 10px;
        top: -5px;
        font-size: 9px;
        font-weight: 600;
        left: 20px;
        color: white;
        line-height: 1;
    }


    .dropdown-menu{
        transform: translate3d(-40%, 34px, 0px) !important;
        max-height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0;
        padding: 0;
        word-break: break-all;
        width: 320px;
        border-radius: 10px;
        a{
            padding: 0;
        }
    }

    .notif-heading{
        padding: 10px 15px;
        font-size: 20px;
        font-weight: 800;
        line-height: 1;
    }


    .dropdown-notif{
        display: grid;
        gap: 10px;
        grid-template-columns: 45px 240px;
        align-items: center;
        padding: 5px 10px;
        margin-bottom: 5px;
        // background-color: #e6e6e6;   

        .image{
            position: relative;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 1px solid #c4c4c4;
            img.profile-image{
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: 55% 4px;
                border-radius: 50%;
            }
            img.icon{
                position: absolute;
                height: 15px;
                bottom: 0px;
                right: 0px;
            }
        }

        .noti{
            font-weight: 300;
            word-break: break-word;
            font-size: 13px;
            display: block;
            white-space: pre-line;
            line-height: 1.2;
            position: relative;
            strong{
                font-weight: 400;
            }

            .date{
                margin-top: 5px;
                p{
                    font-size: 12px;
                    color: #707070;
                    line-height: 1;
                    font-weight: 300;
                }
            }

            .circle{
                position: absolute;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #1876f2;
            }


        }
        
    }
    .padding{
        padding: 10px!important;
    }


}

@media only screen and (max-width:1100px){
    .notifications-dropdown .dropdown-menu{
        transform: translate3d(-50%, 34px, 0px) !important;
    }
}


@media only screen and (max-width:400px){
    .notifications-dropdown .dropdown-menu{
        transform: translate3d(-230px, 34px, 0px) !important;
    }
}