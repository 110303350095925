.channel-forms {
  margin: 30px auto;
  width: 75%;


  .caption{
    margin-bottom: 0!important;
  }

  .check{
    margin-bottom: 15px;
  }

  .bullets{
    margin-bottom: 25px;
    ul{
      margin-bottom: 0;
      list-style: none;
      display: grid;
      gap: 10px;
    }

    .small{
      margin-bottom: 8px;
    }

    button{
      background-color: #3cbbfe;
      border: 0;
      color: white;
      padding: 2px 20px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  span.small{
    font-weight: 300;
    margin-bottom: 15px;
    display: block;
  }
 

  .channel-container{
    padding:  20px 0;
  }

  input[type="text"]{
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  select{
    background-color: #fff;
    border: 1px solid #a2a2a2;
    box-shadow: none;
    width: 100%;
    height: 35px;
    font-size: 16px;
    padding: 5px;
    font-weight: 300;
    border-radius: 5px;
    color: #000;
    margin-bottom: 15px;
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .radio-group{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    label{
      margin-bottom: 0;
      line-height: 1;
      cursor: pointer;
    }
  }

  label {
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
  }

  input[type="text"] {
    background-color: #fff;
    border: 1px solid #a2a2a2;
    box-shadow: none;
    width: 100%;
    height: 35px;
    font-size: 16px;
    padding: 5px;
    font-weight: 300;
    border-radius: 5px;
    color: #000;
    margin-bottom: 15px;
  }
}

.form-submit {
  padding: 5px 15px;
  background-color: #fe0000;
  color: #fff;
  outline: none;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 20px;
}

.channel-upload-group {
  .file-input {
    position: relative;
  }

  .image-preview{
    position: relative;
    >img{
      border: 1px solid #c4c4c4;
      border-radius: 6px;
      width: 100%;
      object-fit: contain;
    }

    a{
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
      display: inline-flex;
      border: 2px solid white;
      border-radius: 50%;

      img{
        height: 20px;
        padding: 0;
        width: auto;
      }
    }


  }

  .image-label {
    background-color: #fff;
    border: 1px solid #a1a1a1;
    font-size: 16px;
    color: #4a4a4a;
    width: fit-content;
    padding: 6px 15px;
    vertical-align: middle;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 5px;
    cursor: pointer;

    input[type="file"] {
      display: none;
    }
  }
}

.arrow-down-gnrl{
  height: 20px;
  width: 20px;
  // transform: rotate(45deg);
  border-style: solid;
  border-width: 0px 1px 1px 0px;
  border-color: #cdc7c7;
  position: absolute;
  background-color: white;
  // top: 23px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  top: calc(100% + 2px);
  z-index: -1;
}

.arrow-down-event {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 33px;
  // left: 8%;
  // top: 24px;
  // left: 8%;
}

.arrow-down-speaker {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 31px;
  // left: 26%;
}

.arrow-down-partner {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 31px;
  // left: 45%;
}

.arrow-down-editChannel {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 31px;
  // left: 64%;
}

.arrow-down-live {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 31px;
  // left: 86%;
}

.arrow-down-editChannelCode {
  // width: 0;
  // height: 0;
  // border-style: solid;
  // border-width: 15px 10px 0 10px;
  // border-color: #ffffff transparent transparent transparent;
  // position: absolute;
  // top: 31px;
  // left: 71%;
}

.live-tab {
  color: #ff0000;
}

.tabs-content ul li {
  // margin: 0px 30px;
  font-size: 14px;
  font-weight: 400;
}

.coverImage {
  width: 100%;
}
