.new-login-screen {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  overflow: auto;

  .gnrl-btn-style {
    width: 300px;
    margin: auto;
    border-radius: 20px;
    border: none;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
    font-family: "Roboto Slab";
    border: 1px solid transparent;
    cursor: pointer;
  }

  .blue-bg {
    background-color: #3284ff;
    color: #ffffff;
    &:hover {
      background-color: rgba($color: #3284ff, $alpha: 0.8);
    }
  }

  .blue-border {
    border-color: #3284ff;
    color: #3284ff;
    background-color: transparent;
  }

  .inner {
    width: 600px;
  }

  .step1 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }

  .step5 ul {
    margin: auto;
  }

  .step4 {
    margin-bottom: 50px;
  }

  .step6{
    display: grid;
    gap: 15px;
    width: 100%;

    .underline{
      width: 100%;
    }

    button{
      width: 320px;
      padding: 12px 0;
    }

    .form-div input{
      width: 320px!important;
      padding: 12px 0;
    }
  }

  .step2,
  .step3,
  .step4,
  .step5 {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    gap: 50px;
    align-items: center;

    ul {
      display: grid;
      margin-bottom: 0;
      list-style: none;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 400px;

      li.active {
        span.number {
          background-color: #ff8888;
          border-color: #ff8888;
          p {
            // font-weight: 400;
          }
        }
        span.label {
          font-weight: 400;
        }
      }

      li {
        position: relative;
        cursor: pointer;

        &:last-of-type {
          &:after {
            content: none;
          }
        }

        &:first-of-type {
          &:before {
            content: none;
          }
        }

        &:after {
          content: "";
          position: absolute;
          height: 1px;
          width: 50%;
          background-color: black;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          content: "";
          position: absolute;
          height: 1px;
          width: calc(50% - 11.5px);
          background-color: black;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }

        span.number {
          display: inline-grid;
          height: 23px;
          width: 23px;
          border-radius: 50%;
          border: 1px solid #ff8888;
          align-items: center;
          justify-content: center;
          p {
            margin-bottom: 0;
            font-size: 13px;
            height: 100%;
            font-weight: 300;
            font-family: "Roboto Slab";
            line-height: 1.69;
          }
        }
        span.label {
          position: absolute;
          top: 33px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 13px;
          font-weight: 300;
          font-family: "Lato";
          line-height: 1;
          color: #787878;
        }
      }
    }
  }

  .logo-div {
    display: grid;
    justify-content: center;
    img {
      width: 150px;
    }
  }

  .tagline-div {
    text-align: center;
    font-family: "Roboto Slab";
    h1 {
      margin-bottom: 0;
      font-weight: 300;
      line-height: 1.2;
      font-size: 30px;
      white-space: pre-line;
      b {
        // font-weight: 400;
        font-family: "Roboto Slab";
      }
    }
  }
  p.acc-btn {
    font-size: 12px;
    text-align: center;
    color: #212529;
    font-weight: 300;
    span {
      color: #3284ff;
      cursor: pointer;
      font-weight: 400;
      text-decoration: underline;
      font-size: 12px;
    }
  }
  .loadergif {
    text-align: center;
  }

  .form-div {
    display: grid;
    gap: 40px;
    justify-content: center;
    font-family: "Lato";

    .error-display {
      font-size: 13px;
      color: #fe0000;
      margin-bottom: 5px;
      font-weight: 300;
      text-align: center;
      text-transform: lowercase;
    }

    .form-inner {
      width: 390px;
      display: grid;
      gap: 10px;

      .error-border {
        border: 0.5px solid #fe0000;
      }

      .inner-col {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
      }

      h1 {
        font-size: 22px;
        font-family: "Roboto Slab";
        margin-bottom: 0;
        font-weight: 200;
        color: black;
        text-align: center;
      }

      p.tagline {
        margin-bottom: 0;
        font-family: "Roboto Slab";
        font-weight: 200;
        font-size: 14px;
        text-align: center;
      }

      input {
        border-radius: 20px;
        border: none;
        background-color: #f2f2f2;
        color: #000000;
        font-weight: 300;
        padding: 8px 0;
        text-indent: 15px;
        font-size: 14px;
        &:focus {
          outline: none;
        }
      }

      select {
        border-radius: 20px;
        border: none;
        background-color: #f2f2f2;
        color: #000000;
        font-weight: 300;
        padding: 8px 0;
        text-indent: 15px;
        font-size: 14px;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 99%;
        background-position-y: 7px;
        appearance: none;
        &:focus {
          outline: none;
        }
      }

      .input-upload-image {
        input[type="file"]{
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
        }

        a{
          position: absolute;
          top: 5px;
          right: 10px;
          transform: rotate(45deg);
          color: #ff0000;
          text-decoration: none;
        }

        img{object-fit: cover;}

        label {
          border: 0;
          font-size: 14px;
          font-weight: 300;
          color: #787878;
          background-color: #f2f2f2;
          position: relative;
          width: 100%;
          padding: 8px 15px;
          cursor: pointer;
          border-radius: 20px;
        }
      }

      .file-div {
        position: relative;
        background-color: #f2f2f2;
        border-radius: 20px;
        padding: 8px 0;
        cursor: pointer;
        label {
          font-size: 14px;
          font-weight: 300;
          color: #787878;
          margin-left: 15px;
          margin-bottom: 0;
        }
        input {
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }

      .gnrl-radio-input {
        position: relative;
        input {
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
        input[type="radio"]:checked ~ label {
          background-color: #ff8888;
        }
        label {
          margin-bottom: 0;
          cursor: pointer;
          display: block;
          border-radius: 20px;
          padding: 8px 0 8px 15px;
          font-size: 14px;
          color: #000000;
          border: 1px solid #ff8888;
          font-weight: 300;
        }
      }

      button {
        background-color: #ff8888;
        border-radius: 20px;
        width: 85px;
        border: none;
        font-size: 14px;
        font-weight: 300;
        padding: 3px 0;
        margin: auto;
        cursor: pointer;
        transition: all 0.3 ease-in-out;

        &:hover {
          background-color: rgba(#ff8888, 0.8);
        }
      }
    }

    .form-outer {
      text-align: center;
      a {
        font-size: 14px;
        text-decoration: underline;
        color: #727272;
        font-weight: 300;
      }
    }
  }

  .linkedin-div {
    display: grid;
    gap: 20px;

    h3 {
      margin-bottom: 0;
      text-align: center;
      font-family: "Lato";
      line-height: 1.4;
      font-size: 18px;
      font-weight: 300;
      color: green;
    }

    .para {
      text-align: center;
      p {
        user-select: none;
        margin-bottom: 0;
        line-height: 1.4;
        font-size: 14px;
        font-weight: 300;
        font-family: "Lato";
      }
    }

    .second-para {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      span {
        user-select: none;
        font-size: 12px;
        font-weight: 300;
        line-height: 1;
        font-family: "lato";
      }
    }

    .linked-btn {
      button {
        border: none;
        color: white;
        border-radius: 20px;
        background-color: #3284ff;
        font-size: 18px;
        font-weight: 300;
        font-family: "lato";
        width: 70%;
        display: block;
        margin: auto;
        padding: 5px 0;
        letter-spacing: 1px;
        cursor: pointer;
        &:hover {
          background-color: rgba(#3284ff, 0.8);
        }
        &:focus {
          outline: none;
        }
      }
    }

    a {
      color: #727272;
      font-family: "Lato";
      text-align: center;
      font-weight: 300;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  .req-invite-div {
    display: grid;
    gap: 10px;
    text-align: center;
    width: 80%;
    margin: auto;

    h1 {
      font-size: 22px;
      font-family: "Roboto Slab";
      margin-bottom: 0;
      font-weight: 200;
      color: black;
    }
  }

  /* This fires as soon as the element enters the DOM */
  .example-appear {
    // margin-top: 150%;
    // visibility: hidden;
  }
  /* This is where we can add the transition*/
  .example-appear-active {
    // visibility: visible;
    margin-top: 0;
    transition: all 0.6s ease-in-out;
  }
  /* This fires as soon as the this.state.showList is false */
  .example-exit {
  }
  /* fires as element leaves the DOM*/
  .example-exit-active {
  }
}

.new-login-screen.isModal{
  p.acc-btn{
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .new-login-screen {
    .inner {
      width: 300px;
    }

    .form-div {
      .form-inner {
        width: 300px;
      }
    }

    .req-invite-div {
      width: 300px;
    }
  }

  .new-login-screen .tagline-div h1 {
    font-size: 18px;
  }

  .new-login-screen .form-div {
    gap: 25px;
  }

  .new-login-screen .req-invite-div h1 {
    font-size: 15px;
  }

  .new-login-screen .gnrl-btn-style {
    width: 90%;
  }

  .new-login-screen .linkedin-div h3 {
    font-size: 14px;
  }

  .new-login-screen .linkedin-div .para p,
  .new-login-screen .form-div .form-outer a,
  .new-login-screen .linkedin-div a {
    font-size: 12px;
    cursor: pointer !important;
  }

  .new-login-screen .linkedin-div .second-para span {
    text-align: center;
    font-size: 11px;
  }

  .new-login-screen .linkedin-div .linked-btn button {
    font-size: 14px;
    width: 80%;
  }

  .new-login-screen {
    .step2,
    .step3,
    .step4,
    .step5 {
      ul {
        width: 300px;
      }
    }
  }
}
