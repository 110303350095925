#login-signup-comp{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner{
        display: grid;
        gap: 20px;
        padding: 0 20px;
    }


    .heading{
        font-weight: 800;
        line-height: 1;
        margin-bottom: 0;
        font-family: 'Lato';
        font-size: 3.75vw;
    }


    button{
        outline: none;
        cursor: pointer;
        background-color: transparent;
        line-height: 1;
        border: 1px solid #e4e4e4;
        border-radius: 40px;
        padding: 17px 0;
        font-weight: 700;
        width: 80%;
        &:focus,&:visited{
            outline: none;
            box-shadow: none;
          }
      
        span.google-logo{
            padding-bottom: 20px;
            position: relative;
            display: inline-block;
            width: 20px;
            vertical-align: sub;
            margin-right: 7px;
            img{
              position: absolute;
              inset: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
        }
    }

    .account{
        margin-bottom: 0;
        font-weight: 600;
        span{
          color: #3cbbfe;
          cursor: pointer;
          font-weight: 600;
        }
    }


    .underline{
        width: 80%;
        text-align: center;
        border-bottom: 2px solid #e4e4e4;
        line-height: 0;
        margin: 15px 0;

        span{
        background-color: white;
        padding: 0 5px;
        font-weight: 600;
        }
    }

    form{
        display: grid;
        gap: 10px;
        width: 80%;

        .form-div{
        input{
            width: 100%;
            border-radius: 40px;
            border: 1px solid #e4e4e4;
            line-height: 1;
            padding: 12px 0;
            text-indent: 15px;
            &:focus{
            box-shadow: none;
            outline: none;
            }
        }

        button{
            width: 100%;
            padding: 12px 0px;
        }
        }

    }

    .error-display{
        color: #ff0000;
        font-size: 12px;
    }

    .apply-btn{
        display: inline-block;
        width: 130px;
        background-color: black;
        color: white;
        cursor: pointer;
        border: 0;
    }
}

#login-signup-comp.popup{
    form,span.underline,button{
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    #login-signup-comp{
        button{
            width: 100%;
        }

        form,span.underline{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    #login-signup-comp{
        .heading{
            font-size: 8vw;
        }
          
        .inside{
            padding: 0;
        }

        form,span.underline{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 567px) and (min-width: 300px) {
    #login-signup-comp{
        form,span.underline{
            width: 100%;
        }
    }
}




