.nav {
  background: #ffffff;
  transition: 350ms;
  position: relative;
  text-align: center;
}

.nav ul {
  padding: 0px;
  width: 100%;
  margin-top: 50px;
}

.nav ul a {
  text-decoration: none;
  color: #090909;
  font-size: 12px;
  height: 100%;
  font-weight: 600;
}

.nav ul p {
  color: #414141;
  font-weight: 300;
  font-size: 13px;
  margin: 0;
  line-height: 1;
}

.navbarText {
  padding: 10px;
  list-style: none;
  cursor: pointer;
}

.navbarText.active {
  background-color: #f6f6f6;
  /* border-radius: px; */
}

.navbarText.active p {
  font-weight: 400;
}

.RightSideBarContainer {
  /* background: #ffffff; */
  /* padding: 10px; */
  /* margin: 10px; */
  /* height: 1020px; */
  /* margin-left: 0; */
  /* margin-top: 10px; */
}

.RightSideBarContainer .Search {
  /* background: #f1f2f6;
  border: 1px solid #e5e6ea;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; */
}

.RightSideBarContainer input {
  /* background: transparent;
  border: none;
  outline: none;
  padding: 0; */
}

.chatBox {
  /* display: flex;
  align-items: center;
  border-top: 1px solid #e4e5e9;
  padding: 10px 0px; */
  display: grid;
  grid-template-columns: 60px calc(100% - 70px);
  align-items: center;
  border-top: 1px solid #e4e5e9;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
}



.dropdownWithImage a {
  color: #000000;
}

.searchRow {
  background: #fff;
}

.chatBox .Message a {
  color: #000000;
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
}

.chatBox .removeMember {
  color: #32bcf6 !important;
}

.chatBox .removeMember img{
  display: none;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 10px;
  left: 0px;
}

.chatBox:hover .removeMember img{
  display: block;
}

.chatBox .Message {
  display: grid;
  grid-template-rows: 1fr;
  gap: 5px;
}

.chatBox .Message span {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
}

.chatBox p {
  /* color: #000000; */
}

.chatBox span {
  /* color: #070707;
  font-weight: 300; */
}

.showMorebtn {
  text-align: center;
}
