.PartnersWrapper {
  height: 100vh;
  overflow-y: scroll;
  /* background: #f0f0f0; */
  margin-top: 30px;
}

.PartnersWrapper::-webkit-scrollbar {
  display: none;
}

.ourPartners {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  text-align: center;
  gap: 10px;
  margin-top: 20px;
  grid-row-gap: 30px;
  /* align-items: center; */
}

.ourPartners h5 {
  text-align: initial;
}

.partner {
  background: #fff;
  padding: 40px 20px;
  transition: all 0.35s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourPartners .speaker .image-div{
  background-color: white;
}

.ourPartners .speaker .image-div img{
  object-fit: contain;
  width: calc(100% - 40px);
  left: 50%;
  transform: translateX(-50%);
}

.ourPartners.remove-grid{
  grid-template-columns: 1fr;
  text-align: start;
}


.ourPartners.remove-grid h5{
  font-weight: 300;

}

/* .add-btn-gnrl-channel button{
  background-color: #565656;
  color: #fff;
  appearance: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 8px;
  line-height: 1;
  margin-right: 10px;
} */

.partner:hover {
  box-shadow: 0 3px 34px rgba(0, 0, 0, 0.25);
}

.partnerContainer {
  /* padding: 20px; */
}

.title h2 {
  color: #404040;
  font-weight: 300;
  font-size: 22px;
}

@media only screen and (max-width: 900px){
  .ourPartners {
    grid-template-columns: repeat(3,1fr);
  }
}


@media only screen and (max-width: 600px){
  .ourPartners {
    grid-template-columns: 1fr;
  }
}