.tabs-content {
  background: #fff;
  border-radius: 10px;
  margin: 12px 0;
  border: 1px solid #cdc7c7;
  // padding: 10px 16px;
  // display: flex;
  // align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  // margin-top: 0px;
  // margin-bottom: 15px;

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    // grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    // gap: 10px;
    // justify-content: space-between;

    li {
      // margin: 0px 20px;
      font-weight: 300;
      cursor: pointer;
      position: relative;
      z-index: 0;
    }
  }

  .main-title {
    padding: 10px 16px;
    background-color: #c4c4c4;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    p {
      color: #000;
    }
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 0 10px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  top: 33px;
  left: 50px;
}

.tabs {
  position: relative;
}
